/* eslint-disable @next/next/no-img-element */
import React, { FC } from 'react'
import Link from 'next/link'

interface Props {
  className?: string
}

const PageNoFound: FC<Props> = (props) => {
  const { className } = props
  return (
    <div className={`page-nofound text-center text-xs py-20 ${className}`}>
      <h2 className="text-18 font-semibold">404 Page Not Found</h2>
      <p className="my-2.5">The page you were looking for does not exist. </p>
      <p className="underline">
        <Link href="/">Continue shopping</Link>
      </p>
    </div>
  )
}

export default PageNoFound
